
import { useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import InputLabel from '@mui/material/InputLabel';
import MDAvatar from "components/MDAvatar";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Button, Modal } from "@mui/material";

import * as  Indoor from "indoorjs/dist/indoor"
import Map, { Marker } from 'react-map-gl';
import { openError } from "store/actions/notification";

// Data
import locationTableData from "./data/locationData";
import subLocationTableData from "./data/subLocationData";
// import FloorPlanImg from "../../assets/images/floor-plan.png";

import { createLocation, deleteLocation, updateLocation, deleteSubLocation, updateSubLocation, createSubLocation } from "../../store/actions/locations";

const iconMarker = require('../../assets/images/icon-marker.png')
const blueMarker = require('../../assets/images/icon-marker-blue.png')
const defaultImg = require("../../assets/images/mark-icon.jpg");


const initLatLng = [103.810715, 1.377445]
const initFloorPlanPos = [0.0, 0.0]

const mapTypeList = ["Global Map", "Floor Plan"]

const Comp = () => {
  const locationList = useSelector((state) => state.dashboard.locations);
  const subLocationList = useSelector((state) => state.dashboard.sublocations);
  const assetList = useSelector((state) => state.dashboard.assets);
  const mapRef = useRef(null)

  const dispatch = useDispatch();
  // const { columns: pColumns, rows: pRows } = userData();
  const [lName, setLName] = useState("");
  const [lDescription, setLDescription] = useState("");
  const [slName, setSLName] = useState(0);
  const [mapType, setMapType] = useState(0);
  const [slDescription, setSLDescription] = useState("");
  const [previewUrl, setPreviewUrl] = useState(defaultImg)
  const [uploadFile, setUploadFile] = useState(null)

  const [slLocation, setSLLocation] = useState(0);

  const [editLMode, setEditLMode] = useState(null);
  const [editSLMode, setEditSLMode] = useState(null);

  const [posLng, setPosLng] = useState(initLatLng[0]);
  const [posLat, setPosLat] = useState(initLatLng[1]);

  const [mapOpen, setMapOpen] = useState(false);
  const [floorPlanOpen, setFloorPlanOpen] = useState(false);

  const handleChangeLocation = (event) => {
    const locationItem = locationList.filter(item => item.pk === event.target.value)[0]
    setSLLocation(event.target.value);
    if (!editSLMode && locationItem?.floor_type === 1) {
      setPosLat(initFloorPlanPos[0])
      setPosLng(initFloorPlanPos[0])
    } else if (!editSLMode) {
      setPosLng(initLatLng[0])
      setPosLat(initLatLng[1])
    }
  };
  const updateMapType = (event) => {
    setMapType(event.target.value);
  };

  const updateLName = (e) => setLName(e.target.value)
  const updateLDescription = (e) => setLDescription(e.target.value)
  const updateSLName = (e) => setSLName(e.target.value)
  const updateSLDescription = (e) => setSLDescription(e.target.value)
  const updatePosLng = (e) => setPosLng(e.target.value)
  const updatePosLat = (e) => setPosLat(e.target.value)
  const onMapClose = () => setMapOpen(false)
  const onFloorPlanClose = () => setFloorPlanOpen(false)

  const dlgStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 0,
  };

  const refreshLFields = () => {
    setEditLMode(null)
    setLName("")
    setLDescription("")
    setPreviewUrl(defaultImg)
    setUploadFile(null)
    setMapType(0)
  };

  const refreshSLFields = () => {
    setEditSLMode(null)
    setSLName(0)
    setSLDescription("")
    setSLLocation(0)
    setPosLat(initLatLng[1])
    setPosLng(initLatLng[0])
  };

  const deleteLList = (pk) => {
    dispatch(deleteLocation(pk));
    refreshLFields();
  };

  const editLList = (i) => {
    const item = locationList[i];
    setEditLMode(item);
    setPreviewUrl(item.floor_plan)
    setMapType(item.floor_type)
    setLName(item.name);
    setLDescription(item.description);
  };

  const editSLList = (i) => {
    const item = subLocationList[i];
    setEditSLMode(item);
    // setSLName(item.name);
    setSLDescription(item.description);
    setSLLocation(item.location);

    const findAsset = assetList.find(asset => asset.location === item.location.toString() && asset.name === item.name);

    if (findAsset) {
      setSLName(findAsset.pk);
    } else {
      setSLName(0);
    }

    if (!(item.pos_lat * 1 === 0 && item.pos_lng * 1 === 0)) {
      setPosLat(item.pos_lat)
      setPosLng(item.pos_lng)
    }
  };

  const deleteSLList = (pk) => {
    dispatch(deleteSubLocation(pk));
    refreshSLFields();
  }


  const updateLSubmit = () => {
    if (editLMode)
      dispatch(updateLocation(editLMode.pk, lName, lDescription, mapType, uploadFile))
    else
      dispatch(createLocation(lName, lDescription, mapType, uploadFile))
    refreshLFields()
  }

  const updateSLSubmit = () => {
    if (slName !== 0) {
      const newAsset = assetList.find(asset => asset.pk === slName);
      if (newAsset) {
        if (editSLMode)
          dispatch(updateSubLocation(editSLMode.pk, newAsset.name, slDescription, slLocation, posLat, posLng))
        else
          dispatch(createSubLocation(newAsset.name, slDescription, slLocation, posLat, posLng))
        refreshSLFields()
      }
    }

  }


  const locationData = locationTableData(locationList, deleteLList, editLList);
  const subLocationData = subLocationTableData(subLocationList, deleteSLList, editSLList, locationList);

  const markerDragEnd = (e) => {
    if (e.lngLat.lng > 180) {
      setPosLng(e.lngLat.lng - 180)
    } else if (e.lngLat.lng < -180) {
      setPosLng(e.lngLat.lng + 180)
    } else {
      setPosLng(e.lngLat.lng)
    }

    setPosLat(e.lngLat.lat)
  }

  const setSelectedFile = (file) => {
    if (file) {
      setUploadFile(file)
      setPreviewUrl(URL.createObjectURL(file))
    } else {
      setUploadFile(null)
      setPreviewUrl(defaultImg)
    }
  }

  const onSelectFile = e => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return
    }
    // I've kept this example simple by using the first image instead of multiple
    setSelectedFile(e.target.files[0]);
  }

  const inputFileRef = useRef(null)
  const openUploadImg = (e) => {
    e.preventDefault();
    inputFileRef.current.click()
  }



  const onMapOpen = () => {
    if (!slLocation) {
      dispatch(openError(true, "Alert", "Select Location First.", ""))
      return
    }

    const locationItem = locationList.find(item => item.pk === slLocation)

    if (locationItem?.floor_type === 1) {
      setFloorPlanOpen(true)

      setTimeout(() => {

        const mapEl = document.querySelector('#indoor-map');
        mapEl.innerHTML = ""
        const map = new Indoor.Map(mapEl, {
          floorplan: new Indoor.Floor({
            url: locationItem.floor_plan,
            width: 720,
            opacity: 0.5,
            zIndex: -1
          }),
          minZoom: 0.1,
          maxZoom: 100
        });

        subLocationList.filter(sLoc => sLoc.location == slLocation).map(item => {
          let marker
          if (item.pk == editSLMode?.pk)
            marker = new Indoor.Marker([posLng, posLat], {
              draggable: true,
              zIndex: 100,
              size: 30,
              icon: { url: iconMarker },
              id: 0
            });
          else {
            marker = new Indoor.Marker([item.pos_lng, item.pos_lat], {
              draggable: false,
              zIndex: 100,
              size: 30,
              icon: { url: blueMarker },
              id: 0
            });
          }
          // eslint-disable-next-line no-loop-func
          marker.on('ready', () => {
            marker.addTo(map);
          });
          return marker
        })

        if (!editSLMode) {
          const marker = new Indoor.Marker([posLng, posLat], {
            draggable: true,
            zIndex: 100,
            size: 30,
            icon: { url: iconMarker },
            id: 0
          });
          marker.on('ready', () => {
            marker.addTo(map);
          });
        }
        map.on('marker:moved', e => {
          setPosLng(e.position.x)
          setPosLat(e.position.y)
        });
      }, 1000);

    } else {
      setMapOpen(true)
    }
  }

  console.log('assetList: ', assetList);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={1}>
          <Grid item xs={3}>
            <Card>
              <MDBox
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                mx={2}
                mt={-3}
                p={2}
                mb={1}
                textAlign="center"
              >
                <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                  Customer Location
                </MDTypography>
              </MDBox>
              <MDBox pt={4} pb={3} px={3}>
                <MDBox component="form" role="form">
                  <MDBox mb={1.5}>
                    <MDInput type="text" label="Location Name" fullWidth value={lName} onChange={updateLName} />
                  </MDBox>
                  <MDBox mb={1.5}>
                    <MDInput type="text" label="Description" fullWidth value={lDescription} onChange={updateLDescription} />
                  </MDBox>

                  <MDBox mb={1.5}>
                    <div className="select-control">
                      <FormControl sx={{ m: 0, width: '100%' }}>
                        <InputLabel id="demo-simple-select-helper-label">Map Type</InputLabel>
                        <Select
                          labelId="demo-simple-select-helper-label"
                          value={mapType}
                          onChange={updateMapType}
                        >
                          {
                            mapTypeList.map((item, i) => <MenuItem key={Math.random()} value={i}>{item}</MenuItem>)
                          }
                        </Select>
                      </FormControl>
                    </div>
                  </MDBox>

                  {mapType === 1 &&
                    <MDBox display="flex" alignItems="center" lineHeight={1} mb={2} className="logo-image" onClick={openUploadImg}>
                      <MDAvatar src={previewUrl} name="Logo Image" size="lg" shadow="sm" />
                      <MDTypography variant="button" fontWeight="medium" ml={1} lineHeight={2}>
                        &nbsp;&nbsp;&nbsp; Upload Image
                      </MDTypography>
                    </MDBox>
                  }
                  {mapType === 1 &&
                    <input
                      style={{ display: "none" }}
                      type="file"
                      ref={inputFileRef}
                      onChange={onSelectFile}
                    />
                  }

                  <MDBox mt={4} mb={1}>
                    <MDButton variant="gradient" color={editLMode ? "success" : "info"} fullWidth onClick={updateLSubmit}>
                      {editLMode ? "Update" : "Add"}
                    </MDButton>
                  </MDBox>
                  <MDBox mt={1} mb={1}>
                    <MDButton variant="gradient" color="error" fullWidth onClick={refreshLFields}>
                      Cancel
                    </MDButton>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>

          <Grid item xs={9}>
            <Card>

              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Customer Location
                </MDTypography>
              </MDBox>

              <MDBox pt={3}>
                <DataTable
                  table={locationData}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {/* <MDBox pt={6} pb={3}>
        <Grid container spacing={1}>
          <Grid item xs={3}>
            <Card>
              <MDBox
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                mx={2}
                mt={-3}
                p={2}
                mb={1}
                textAlign="center"
              >
                <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                  Sub Location
                </MDTypography>
              </MDBox>
              <MDBox pt={4} pb={3} px={3}>
                <MDBox component="form" role="form">
                  <MDBox mb={1.5}>
                    <MDInput type="text" label="Name" fullWidth value={lName} onChange={updateLName} />
                  </MDBox>
                  <MDBox mb={1.5}>
                    <MDInput type="text" label="Description" fullWidth value={lDescription} onChange={updateLDescription} />
                  </MDBox>
                  <MDBox mb={1.5}>
                    <MDInput type="number" label="Longitude" fullWidth value={posLng} onChange={updatePosLng} />
                  </MDBox>

                  <MDBox mb={1.5}>
                    <MDInput type="number" label="Latitude" fullWidth value={posLat} onChange={updatePosLat} />
                  </MDBox>

                  <MDBox mb={1.5}>
                    <div className="select-control">
                      <FormControl sx={{ m: 0, width: '100%' }}>
                        <InputLabel id="demo-simple-select-helper-label">Location</InputLabel>

                        <Select
                          labelId="demo-simple-select-helper-label"

                          value={slLocation}

                          onChange={handleChangeLocation}
                        >
                          <MenuItem value={0}>
                            <em>None</em>
                          </MenuItem>
                          {
                            locationList.map(item => <MenuItem key={Math.random()} value={item.pk}>{item.name}</MenuItem>)
                          }
                        </Select>
                      </FormControl>
                    </div>
                  </MDBox>

                  <MDBox mt={4} mb={1}>
                    <MDButton variant="gradient" color={editLMode ? "success" : "info"} fullWidth onClick={updateLSubmit}>
                      {editLMode ? "Update" : "Add"}
                    </MDButton>
                  </MDBox>
                  <MDBox mt={1} mb={1}>
                    <MDButton variant="gradient" color="error" fullWidth onClick={refreshLFields}>
                      Cancel
                    </MDButton>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>

          <Grid item xs={9}>
            <Card>

              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Sub Location
                </MDTypography>
              </MDBox>

              <MDBox pt={3}>
                <DataTable
                  table={subLocationData}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox> */}
      <MDBox pt={6} pb={3}>
        <Grid container spacing={1}>
          <Grid item xs={3}>
            <Card>
              <MDBox
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                mx={2}
                mt={-3}
                p={2}
                mb={1}
                textAlign="center"
              >
                <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                  Asset Location
                </MDTypography>
              </MDBox>
              <MDBox pt={4} pb={3} px={3}>
                <MDBox component="form" role="form">
                  <MDBox mb={1.5}>
                    <div className="select-control">
                      <FormControl sx={{ m: 0, width: '100%' }}>
                        <InputLabel id="demo-simple-select-helper-label">Asset ID</InputLabel>

                        <Select
                          labelId="demo-simple-select-helper-label"

                          value={slName}

                          onChange={updateSLName}
                        >
                          <MenuItem value={0}>
                            <em>None</em>
                          </MenuItem>
                          {
                            assetList.filter(asset => slLocation === 0 ? asset : asset.location === slLocation.toString()).map(item => <MenuItem key={Math.random()} value={item.pk}>{item.name}</MenuItem>)
                          }
                        </Select>
                      </FormControl>
                    </div>
                  </MDBox>
                  {/* <MDBox mb={1.5}>
                    <MDInput type="text" label="Name" fullWidth value={slName} onChange={updateSLName} />
                  </MDBox> */}

                  <MDBox mb={1.5}>
                    <MDInput type="text" label="Description" fullWidth value={slDescription} onChange={updateSLDescription} />
                  </MDBox>

                  <MDBox mb={1.5}>
                    <div className="select-control">
                      <FormControl sx={{ m: 0, width: '100%' }}>
                        <InputLabel id="demo-simple-select-helper-label">Location</InputLabel>

                        <Select
                          labelId="demo-simple-select-helper-label"

                          value={slLocation}

                          onChange={handleChangeLocation}
                        >
                          <MenuItem value={0}>
                            <em>None</em>
                          </MenuItem>
                          {
                            locationList.map(item => <MenuItem key={Math.random()} value={item.pk}>{item.name}</MenuItem>)
                          }
                        </Select>
                      </FormControl>
                    </div>
                  </MDBox>

                  {/* <MDBox mb={1.5}>
                    <div className="select-control">
                      <FormControl sx={{ m: 0, width: '100%' }}>
                        <InputLabel id="demo-simple-select-helper-label">Sub Location</InputLabel>

                        <Select
                          labelId="demo-simple-select-helper-label"

                          value={slLocation}

                          onChange={handleChangeLocation}
                        >
                          <MenuItem value={0}>
                            <em>None</em>
                          </MenuItem>
                          {
                            locationList.map(item => <MenuItem key={Math.random()} value={item.pk}>{item.name}</MenuItem>)
                          }
                        </Select>
                      </FormControl>
                    </div>
                  </MDBox> */}

                  <MDBox mb={1.5}>
                    <Button onClick={onMapOpen} className="open-map">
                      Open Map
                    </Button>
                    <MDInput type="number" label="Longitude" fullWidth value={posLng} onChange={updatePosLng} />
                  </MDBox>

                  <MDBox mb={1.5}>
                    <MDInput type="number" label="Latitude" fullWidth value={posLat} onChange={updatePosLat} />
                  </MDBox>

                  <MDBox mt={4} mb={1}>
                    <MDButton variant="gradient" color={editSLMode ? "success" : "info"} fullWidth onClick={updateSLSubmit}>
                      {editSLMode ? "Update" : "Add"}
                    </MDButton>
                  </MDBox>
                  <MDBox mt={1} mb={1}>
                    <MDButton variant="gradient" color="error" fullWidth onClick={refreshSLFields}>
                      Cancel
                    </MDButton>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>

          <Grid item xs={9}>
            <Card>

              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Asset Location
                </MDTypography>
              </MDBox>

              <MDBox pt={3}>
                <DataTable
                  table={subLocationData}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>

            </Card>
          </Grid>
        </Grid>

        <Modal
          open={mapOpen}
          onClose={onMapClose}
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
        >
          <MDBox mt={1} mb={1} sx={{ ...dlgStyle, width: '72vw', height: '72vh' }}>
            <div style={{ width: '100%', height: '100%' }}>
              <Map
                ref={mapRef}
                initialViewState={{
                  longitude: initLatLng[0],
                  latitude: initLatLng[1],
                  zoom: 10.5
                }}
                mapStyle="mapbox://styles/mapbox/streets-v9"
                mapboxAccessToken="pk.eyJ1IjoiaW1hZ2lucmF5IiwiYSI6ImNrN2YwdXV3MDBoYTQzZ280dGYzdG40cmQifQ.EPVSF0iRfdmOey75gDrBLQ"
              >
                {
                  (!editSLMode) && <Marker longitude={(posLng < 180 && posLng > -180) ? posLng : initLatLng[0]} latitude={(posLat < 90 && posLat > -90) ? posLat : initLatLng[1]} anchor="bottom" draggable onDragEnd={markerDragEnd} >
                    <img width={21} src={iconMarker} alt="marker" />
                  </Marker>
                }

                {
                  subLocationList.filter(sLoc => sLoc.location == slLocation).map(item => {
                    if (item.pk === editSLMode?.pk) {
                      if (posLng < 180 && posLng > -180 && posLng !== initLatLng[0]) {
                        return <Marker key={item.pk} longitude={posLng} latitude={(posLat < 90 && posLat > -90) ? posLat : initLatLng[1]} anchor="bottom" draggable onDragEnd={markerDragEnd} >
                          <img width={21} src={iconMarker} alt="marker" />
                        </Marker>
                      }
                      return <Marker key={item.pk} longitude={(item.pos_lng < 180 && item.pos_lng > -180) ? item.pos_lng : initLatLng[0]}
                        latitude={(item.pos_lat < 90 && item.pos_lat > -90) ? item.pos_lat : initLatLng[1]} anchor="bottom" draggable onDragEnd={markerDragEnd} >
                        <img width={21} src={iconMarker} alt="marker" />
                      </Marker>
                    }
                    return <Marker key={item.pk} longitude={(item.pos_lng < 180 && item.pos_lng > -180) ? item.pos_lng : initLatLng[0]}
                      latitude={(item.pos_lat < 90 && item.pos_lat > -90) ? item.pos_lat : initLatLng[1]} anchor="bottom" onDragEnd={markerDragEnd} >
                      <img width={21} src={blueMarker} alt="marker" />
                    </Marker>
                  })
                }
              </Map>
            </div>
          </MDBox>
        </Modal>

        <Modal
          open={floorPlanOpen}
          onClose={onFloorPlanClose}
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
        >
          <MDBox mt={1} mb={1} sx={{ ...dlgStyle, width: '72vw', height: '72vh' }}>
            <div style={{ width: '100%', height: '100%' }} id="indoor-map">
              &nbsp;
            </div>
          </MDBox>
        </Modal>

      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Comp;
