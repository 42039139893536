
import { useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
// import Switch from "@mui/material/Switch";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { useSelector, useDispatch } from "react-redux";

// Data
import tableData from "./data/tableData";

// Redux Stuff
import { createAlarm, deleteAlarm, updateAlarm } from "../../store/actions/alarms";


const Comp = () => {
  const dispatch = useDispatch()
  const list = useSelector((state) => state.dashboard.alarms)
  const locationList = useSelector((state) => state.dashboard.locations);
  const assetList = useSelector((state) => state.dashboard.assets);
  const [editMode, setEditMode] = useState(null)

  // const [name, setName] = useState("")
  const [description, setDescription] = useState("")

  const [unbalance, setUnbalance] = useState(50)
  const [unbalanceT, setUnbalanceT] = useState({ Normal: 30, Notification: 50, Critical: 80, Lethal: 90 })

  const [misalignment, setMisalignment] = useState(50)
  const [misalignmentT, setMisalignmentT] = useState({ Normal: 30, Notification: 50, Critical: 80, Lethal: 90 })

  const [motorFault, setMotorFault] = useState(50)
  const [motorFaultT, setMotorFaultT] = useState({ Normal: 30, Notification: 50, Critical: 80, Lethal: 90 })

  const [bearing, setBearing] = useState(50)
  const [bearingT, setBearingT] = useState({ Normal: 30, Notification: 50, Critical: 80, Lethal: 90 })

  const [shaftSway, setShaftSway] = useState(50)
  const [shaftSwayT, setShaftSwayT] = useState({ Normal: 30, Notification: 50, Critical: 80, Lethal: 90 })

  const [looseness, setLooseness] = useState(50)
  const [loosenessT, setLoosenessT] = useState({ Normal: 30, Notification: 50, Critical: 80, Lethal: 90 })

  const [vanePass, setVanePass] = useState(50)
  const [vanePassT, setVanePassT] = useState({ Normal: 30, Notification: 50, Critical: 80, Lethal: 90 })

  const [slLocation, setSLLocation] = useState(0);
  const [slName, setSLName] = useState(0);

  const updateSLName = (e) => {
    console.log('updateSLName: ', e.target.value);
    setSLName(e.target.value)
  }
  const handleChangeLocation = (event) => {
    setSLLocation(event.target.value);
  };

  const refreshFields = () => {
    setEditMode(null);
    // setName("");
    setDescription("");
    setSLLocation(0);
    setSLName(0);
  }

  const deleteList = (id) => {
    dispatch(deleteAlarm(id));
    refreshFields();
  }

  const editList = (i, flag) => {
    const item = list[i]
    if (!flag) {
      setEditMode(item)
      // setName(item.name)
      setDescription(item.description)

      const findAsset = assetList.find(asset => asset.name === item.name);
      if (findAsset) {
        const findLocation = locationList.find(location => location.pk.toString() === findAsset.location);
        if (findLocation) {
          setSLLocation(findLocation.pk)
        }
        setSLName(findAsset.pk);
      } else {
        setSLName(0);
      }
    }

    const { json_data } = item

    setUnbalance(json_data.unbalance)
    setUnbalanceT(json_data.unbalanceT)

    setMisalignment(json_data.misalignment)
    setMisalignmentT(json_data.misalignmentT)

    setMotorFault(json_data.motorFault)
    setMotorFaultT(json_data.motorFaultT)

    setBearing(json_data.bearing)
    setBearingT(json_data.bearingT)

    setShaftSway(json_data.shaftSway)
    setShaftSwayT(json_data.shaftSwayT)

    setLooseness(json_data.looseness)
    setLoosenessT(json_data.loosenessT)

    setVanePass(json_data.vanePass)
    setVanePassT(json_data.vanePassT)

  }

  const showData = tableData(list, deleteList, editList);
  // const updateName = (e) => setName(e.target.value);
  const updateDescription = (e) => setDescription(e.target.value);

  const updateUnbalance = (e) => setUnbalance(e.target.value);
  const updateUnbalanceT = (e, key) => {
    const newThreshold = { ...unbalanceT }
    newThreshold[key] = e.target.value
    setUnbalanceT(newThreshold)
  }

  const updateMisalignment = (e) => setMisalignment(e.target.value);
  const updateMisalignmentT = (e, key) => {
    const newThreshold = { ...misalignmentT }
    newThreshold[key] = e.target.value
    setMisalignmentT(newThreshold)
  }

  const updateMotorFault = (e) => setMotorFault(e.target.value);
  const updateMotorFaultT = (e, key) => {
    const newThreshold = { ...motorFaultT }
    newThreshold[key] = e.target.value
    setMotorFaultT(newThreshold)
  }

  const updateBearing = (e) => setBearing(e.target.value);
  const updateBearingT = (e, key) => {
    const newThreshold = { ...bearingT }
    newThreshold[key] = e.target.value
    setBearingT(newThreshold)
  }

  const updateShaftSway = (e) => setShaftSway(e.target.value);
  const updateShaftSwayT = (e, key) => {
    const newThreshold = { ...shaftSwayT }
    newThreshold[key] = e.target.value
    setShaftSwayT(newThreshold)
  }

  const updateLooseness = (e) => setLooseness(e.target.value);
  const updateLoosenessT = (e, key) => {
    const newThreshold = { ...loosenessT }
    newThreshold[key] = e.target.value
    setLoosenessT(newThreshold)
  }

  const updateVanePass = (e) => setVanePass(e.target.value);
  const updateVanePassT = (e, key) => {
    const newThreshold = { ...vanePassT }
    newThreshold[key] = e.target.value
    setVanePassT(newThreshold)
  }


  const updateSubmit = () => {

    const json_data = {
      unbalance, unbalanceT, misalignment, misalignmentT, motorFault, motorFaultT, bearing, bearingT
      , shaftSway, shaftSwayT, looseness, loosenessT, vanePass, vanePassT
    }

    if (slName !== 0) {
      const newAsset = assetList.find(asset => asset.pk === slName);

      if (newAsset) {
        if (editMode)
          dispatch(updateAlarm(editMode.pk, newAsset.name, description, json_data));
        else
          dispatch(createAlarm(newAsset.name, description, json_data));

        refreshFields();
      }
    }
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={1} className="systems">
          <Grid item xs={3}>
            <Card>
              <MDBox
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                mx={2}
                mt={-3}
                p={2}
                mb={1}
                textAlign="center"
              >
                <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                  Radar Chart Settings
                </MDTypography>
              </MDBox>

              <MDBox pt={4} pb={3} px={3}>
                <MDBox component="form" role="form">

                  <MDBox mb={1.5}>
                    <div className="select-control">
                      <FormControl sx={{ m: 0, width: '100%' }}>
                        <InputLabel id="demo-simple-select-helper-label">Group Name</InputLabel>

                        <Select
                          labelId="demo-simple-select-helper-label"

                          value={slLocation}

                          onChange={handleChangeLocation}
                        >
                          <MenuItem value={0}>
                            <em>None</em>
                          </MenuItem>
                          {
                            locationList.map(item => <MenuItem key={Math.random()} value={item.pk}>{item.name}</MenuItem>)
                          }
                        </Select>
                      </FormControl>
                    </div>
                  </MDBox>
                  <MDBox mb={1.5}>
                    <div className="select-control">
                      <FormControl sx={{ m: 0, width: '100%' }}>
                        <InputLabel id="demo-simple-select-helper-label">Asset ID</InputLabel>

                        <Select
                          labelId="demo-simple-select-helper-label"

                          value={slName}

                          onChange={updateSLName}
                        >
                          <MenuItem value={0}>
                            <em>None</em>
                          </MenuItem>
                          {
                            assetList.filter(asset => slLocation === 0 ? asset : asset.location === slLocation.toString()).map(item => <MenuItem key={Math.random()} value={item.pk}>{item.name}</MenuItem>)
                          }
                        </Select>
                      </FormControl>
                    </div>
                  </MDBox>

                  {/* <MDBox mb={1.5}>
                    <MDInput type="text" label="Asset ID" fullWidth value={name} onChange={updateName} />
                  </MDBox> */}

                  <MDBox mb={2}>
                    <MDInput type="text" label="Description" fullWidth value={description} onChange={updateDescription} />
                  </MDBox>

                  <MDBox mb={0.5}>
                    <MDInput className="label-amp" type="number" label="Unbalance" fullWidth value={unbalance} onChange={updateUnbalance} />
                  </MDBox>

                  <MDBox mb={2}>
                    <Grid container spacing={0.5}>
                      {
                        Object.keys(unbalanceT).map((key) => <Grid item xs={6} key={key}>
                          <MDInput type="number" label={key} fullWidth value={unbalanceT[key]} onChange={(e) => updateUnbalanceT(e, key)} />
                        </Grid>)
                      }
                    </Grid>
                  </MDBox>

                  <MDBox mb={0.5}>
                    <MDInput type="number" className="label-amp" label="Misalignment" fullWidth value={misalignment} onChange={updateMisalignment} />
                  </MDBox>
                  <MDBox mb={2}>
                    <Grid container spacing={0.5}>
                      {
                        Object.keys(misalignmentT).map((key) => <Grid item xs={6} key={key}>
                          <MDInput type="number" label={key} fullWidth value={misalignmentT[key]} onChange={(e) => updateMisalignmentT(e, key)} />
                        </Grid>)
                      }
                    </Grid>
                  </MDBox>

                  <MDBox mb={0.5}>
                    <MDInput type="number" className="label-amp" label="Motor Fault" fullWidth value={motorFault} onChange={updateMotorFault} />
                  </MDBox>
                  <MDBox mb={2}>
                    <Grid container spacing={0.5}>
                      {
                        Object.keys(motorFaultT).map((key) => <Grid item xs={6} key={key}>
                          <MDInput type="number" label={key} fullWidth value={motorFaultT[key]} onChange={(e) => updateMotorFaultT(e, key)} />
                        </Grid>)
                      }
                    </Grid>
                  </MDBox>

                  <MDBox mb={0.5}>
                    <MDInput type="number" className="label-amp" label="Bearing" fullWidth value={bearing} onChange={updateBearing} />
                  </MDBox>
                  <MDBox mb={2}>
                    <Grid container spacing={0.5}>
                      {
                        Object.keys(bearingT).map((key) => <Grid item xs={6} key={key}>
                          <MDInput type="number" label={key} fullWidth value={bearingT[key]} onChange={(e) => updateBearingT(e, key)} />
                        </Grid>)
                      }
                    </Grid>
                  </MDBox>

                  <MDBox mb={0.5}>
                    <MDInput type="number" className="label-amp" label="Shaft Sway" fullWidth value={shaftSway} onChange={updateShaftSway} />
                  </MDBox>
                  <MDBox mb={2}>
                    <Grid container spacing={0.5}>
                      {
                        Object.keys(shaftSwayT).map((key) => <Grid item xs={6} key={key}>
                          <MDInput type="number" label={key} fullWidth value={shaftSwayT[key]} onChange={(e) => updateShaftSwayT(e, key)} />
                        </Grid>)
                      }
                    </Grid>
                  </MDBox>

                  <MDBox mb={0.5}>
                    <MDInput type="number" className="label-amp" label="Looseness" fullWidth value={looseness} onChange={updateLooseness} />
                  </MDBox>
                  <MDBox mb={2}>
                    <Grid container spacing={0.5}>
                      {
                        Object.keys(loosenessT).map((key) => <Grid item xs={6} key={key}>
                          <MDInput type="number" label={key} fullWidth value={loosenessT[key]} onChange={(e) => updateLoosenessT(e, key)} />
                        </Grid>)
                      }
                    </Grid>
                  </MDBox>

                  <MDBox mb={0.5}>
                    <MDInput type="number" className="label-amp" label="Vane Pass" fullWidth value={vanePass} onChange={updateVanePass} />
                  </MDBox>
                  <MDBox mb={2}>
                    <Grid container spacing={0.5}>
                      {
                        Object.keys(vanePassT).map((key) => <Grid item xs={6} key={key}>
                          <MDInput type="number" label={key} fullWidth value={vanePassT[key]} onChange={(e) => updateVanePassT(e, key)} />
                        </Grid>)
                      }
                    </Grid>
                  </MDBox>

                  <MDBox mt={4} mb={1}>
                    <MDButton variant="gradient" color={editMode ? "success" : "info"} fullWidth onClick={updateSubmit}>
                      {editMode ? "Update" : "Add"}
                    </MDButton>
                  </MDBox>

                  <MDBox mt={1} mb={1}>
                    <MDButton variant="gradient" color="error" fullWidth onClick={refreshFields}>
                      Cancel
                    </MDButton>
                  </MDBox>

                </MDBox>
              </MDBox>
            </Card>
          </Grid>

          <Grid item xs={9}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Alarm List
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={showData}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>

        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Comp;
