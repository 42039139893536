
// @mui material components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LanguageIcon from '@mui/icons-material/Language';
import PropTypes from 'prop-types';

export default function data(list, selectMap, mapIndex) {

  const HeadItem = ({ name, id }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1} onClick={() => selectMap(id)}>
      <LanguageIcon fontSize="small" />
      <MDTypography display="block" variant="button" fontWeight="medium" ml={1} lineHeight={1} className={id === mapIndex? "selected" : ""} >
        {name}
      </MDTypography>
    </MDBox>
  );

  HeadItem.propTypes = {
    name: PropTypes.string,
    id: PropTypes.number
  };

  HeadItem.defaultProps = {
    name: "",
    id: 0
  }


  return {
    columns: [
      { Header: "Location Name", accessor: "groupname", align: "left" },
      { Header: "Map Type", accessor: "map_type", align: "center" },
      { Header: "Status", accessor: "status", align: "center" },
    ],

    rows: list.map((item, i) =>
    ({
      groupname: <HeadItem name={item.name} id={i} />,

      map_type: (
        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          {item?.floor_type === 1 ? "F-Map" : "G-Map"}
        </MDTypography>
      ),
      status: (
        <MDTypography component="a" href="#" color="success">
          OK
        </MDTypography>
      )
    })
    )
  };
}
