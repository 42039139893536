export const AUTH_START = "AUTH_START";
export const AUTH_SUCCESS = "AUTH_SUCCESS";

export const AUTH_FAIL = "AUTH_FAIL";
export const AUTH_LOGOUT = "AUTH_LOGOUT";

export const ASSIGNDLG_OPEN = "ASSIGNDLG_OPEN";
export const ASSIGNDLG_CLOSE = "ASSIGNDLG_CLOSE";

export const FILTERDLG_OPEN = "FILTERDLG_OPEN";
export const FILTERDLG_CLOSE = "FILTERDLG_CLOSE";

export const ACCESSDLG_OPEN = "ACCESSDLG_OPEN";
export const ACCESSDLG_CLOSE = "ACCESSDLG_CLOSE";

// GET ITEMS FROM SERVER

export const GET_PERMISSIONS = "GET_PERMISSIONS"
export const GET_ROLES = "GET_ROLES"
export const GET_USERS = "GET_USERS"
export const GET_COMPANIES = "GET_COMPANIES"
export const GET_GROUPS = "GET_GROUPS"
export const GET_ASSETS = "GET_ASSETS"
export const GET_ALARMS = "GET_ALARMS"
export const GET_VPNS = "GET_VPNS"
export const GET_LOCATIONS = "GET_LOCATIONS"
export const GET_SUBLOCATIONS = "GET_SUBLOCATIONS"
export const GET_SYSTEMS = "GET_SYSTEMS"

// NOTIFICATIONS

export const OPEN_SUCCESS_NOTIFICATION = "OPEN_SUCCESS_NOTIFICATION"
export const CLOSE_SUCCESS_NOTIFICATION = "CLOSE_SUCCESS_NOTIFICATION"

export const OPEN_INFO_NOTIFICATION = "OPEN_INFO_NOTIFICATION"
export const CLOSE_INFO_NOTIFICATION = "CLOSE_INFO_NOTIFICATION"

export const OPEN_ERROR_NOTIFICATION = "OPEN_ERROR_NOTIFICATION"
export const CLOSE_ERROR_NOTIFICATION = "CLOSE_ERROR_NOTIFICATION"

