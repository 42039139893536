/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Peter Y (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useMemo } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation, useNavigate } from "react-router-dom";
// import { Redirect } from 'react-router'

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDSnackbar from "components/MDSnackbar";


// Material Dashboard 2 React example components
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";

// Material Dashboard 2 React themes
import theme from "assets/theme";
import themeRTL from "assets/theme/theme-rtl";

// Material Dashboard 2 React Dark Mode themes
import themeDark from "assets/theme-dark";
import themeDarkRTL from "assets/theme-dark/theme-rtl";

// RTL plugins
// import rtlPlugin from "stylis-plugin-rtl";
// import { CacheProvider } from "@emotion/react";
// import createCache from "@emotion/cache";

// Material Dashboard 2 React routes
import routes from "routes";

// Material Dashboard 2 React contexts
import { useMaterialUIController, setMiniSidenav, setOpenConfigurator } from "context";

// Images
import brandWhite from "assets/images/logo-ct.png";
import brandDark from "assets/images/logo-ct-dark.png";

// Redux

import { useSelector, useDispatch } from "react-redux";

import { getAlarms } from "./store/actions/alarms";
import { getPermissions } from "./store/actions/permission";
import { getRoles } from "./store/actions/user_role";
import { getUsers, getCompanies, getGroups } from './store/actions/users';
import { getAssets } from './store/actions/assets';
import { getVPNs } from './store/actions/vpns';
import { getSystems } from './store/actions/systems';
import { authCheckState } from './store/actions/auth';

import { getLocations, getSubLocations } from './store/actions/locations';

import { closeSuccess, closeError, closeInfo } from './store/actions/notification';
/// app.js
import 'react-svg-radar-chart/build/css/index.css'
import 'mapbox-gl/dist/mapbox-gl.css';
import "./App.scss"

const App = () => {
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  // const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();

  // Cache for the rtl
  useMemo(() => {
    // const cacheRtl = createCache({
    //   key: "rtl",
    //   stylisPlugins: [rtlPlugin],
    // });
    // setRtlCache(cacheRtl);
  }, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
  const loggedIn = useSelector((state) => state.auth.token);
  const successData = useSelector((state) => state.notification.success);
  const errorData = useSelector((state) => state.notification.error);
  const infoData = useSelector((state) => state.notification.info);


  const navi = useNavigate();
  const dashboardDispatch = useDispatch();

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;


    if (!loggedIn)
      navi('/dashboard/sign-in')
    else if (pathname.includes("/dashboard/sign-in")) navi('/dashboard')

    if (loggedIn) {
      dashboardDispatch(getPermissions())
      dashboardDispatch(getRoles())
      dashboardDispatch(getCompanies())
      dashboardDispatch(getGroups())
      dashboardDispatch(getUsers())
      dashboardDispatch(getAssets())
      dashboardDispatch(getVPNs())
      dashboardDispatch(getLocations())
      dashboardDispatch(getSubLocations())
      dashboardDispatch(getSystems())
      dashboardDispatch(getAlarms())
    }

    dashboardDispatch(authCheckState())


  }, [pathname, loggedIn]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });
  const closeSuccessSB = () => dashboardDispatch(closeSuccess())
  const closeErrorSB = () => dashboardDispatch(closeError())
  const closeInfoSB = () => dashboardDispatch(closeInfo())

  const configsButton = 
      <MDBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="3.25rem"
        height="3.25rem"
        bgColor="white"
        shadow="sm"
        borderRadius="50%"
        position="fixed"
        right="2rem"
        bottom="2rem"
        zIndex={99}
        color="dark"
        sx={{ cursor: "pointer" }}
        onClick={handleConfiguratorOpen}
      >
        <Icon fontSize="small" color="inherit">
          settings
        </Icon>
      </MDBox>


  return direction === "rtl" ? (
    // <CacheProvider value={rtlCache}>
    <ThemeProvider theme={darkMode ? themeDarkRTL : themeRTL}>
      <CssBaseline />
      {layout === "dashboard" && (
        <>
          <Sidenav
            color={sidenavColor}
            brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
            brandName="Admin Panel"
            routes={routes}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
          <Configurator />
          {configsButton}
        </>
      )}
      {layout === "vr" && <Configurator />}
      <Routes>
        {getRoutes(routes)}
        <Route path="*" element={<Navigate to="/dashboard" />} />
      </Routes>
      
      <MDSnackbar
        color="success"
        icon="check"
        title={successData.title}
        content={successData.content}
        dateTime={successData.dateTime}
        open={successData.open}
        onClose={closeSuccessSB}
        close={closeSuccessSB}
        bgWhite
      />

      <MDSnackbar
        color="error"
        icon="warning"
        title={errorData.title}
        content={errorData.content}
        dateTime={errorData.dateTime}
        open={errorData.open}
        onClose={closeErrorSB}
        close={closeErrorSB}
        bgWhite
      />

      <MDSnackbar
        icon="notifications"
        title={infoData.title}
        content={infoData.content}
        dateTime={infoData.dateTime}
        open={infoData.open}
        onClose={closeInfoSB}
        close={closeInfoSB}
      />

    </ThemeProvider>
    // </CacheProvider>
  ) : (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      {layout === "dashboard" && (
        <>
          <Sidenav
            color={sidenavColor}
            brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
            brandName="Admin Panel"
            routes={routes}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
          <Configurator />
          {configsButton}
        </>
      )}
      {layout === "vr" && <Configurator />}
      <Routes>
        {getRoutes(routes)}
        <Route path="*" element={<Navigate to="/dashboard" />} />
      </Routes>
      
      <MDSnackbar
        color="success"
        icon="check"
        title={successData.title}
        content={successData.content}
        dateTime={successData.dateTime}
        open={successData.open}
        onClose={closeSuccessSB}
        close={closeSuccessSB}
        bgWhite
      />

      <MDSnackbar
        color="error"
        icon="warning"
        title={errorData.title}
        content={errorData.content}
        dateTime={errorData.dateTime}
        open={errorData.open}
        onClose={closeErrorSB}
        close={closeErrorSB}
        bgWhite
      />

      <MDSnackbar
        icon="notifications"
        title={infoData.title}
        content={infoData.content}
        dateTime={infoData.dateTime}
        open={infoData.open}
        onClose={closeInfoSB}
        close={closeInfoSB}
      />
    </ThemeProvider>
  );
}

export default App
