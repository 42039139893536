/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Peter Y (https://www.creative-tim.com)

Coded by www.creative-tim.com
 =========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
// import Grid from "@mui/material/Grid";
// import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import Grid from "@mui/material/Grid";
import MDButton from "components/MDButton";
import Card from "@mui/material/Card";
import DataTable from "examples/Tables/DataTable";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Map, { Marker } from 'react-map-gl';


import * as  Indoor from "indoorjs/dist/indoor";
import { useSelector } from "react-redux";
import locationTableData from "./data/locationData";
// import FloorPlanImg from "../../assets/images/floor-plan.png";
import iconMarker from '../../assets/images/icon-marker.png';

import { getSystemStatus } from '../../store/actions/systems'

const initialPos = [103.810715, 1.377445]

const Comp = () => {
  const locationList = useSelector((state) => state.dashboard.locations);
  const subLocationList = useSelector((state) => state.dashboard.sublocations);
  const assetList = useSelector((state) => state.dashboard.assets);
  const [subLocations, setSubLocations] = useState([])
  const [mapIndex, setMapIndex] = useState(0);
  const mapRef = useRef(null)

  const markerList = [];

  if (subLocationList.length > 0) {
    subLocationList.map(sLoc => {
      const locationItem = locationList.filter(item => item.pk === sLoc.location)[0]
      markerList.push({
        id: sLoc.pk,
        location: locationItem?.name,
        name: sLoc.name,
        lng: sLoc?.pos_lng ? sLoc?.pos_lng : initialPos[0],
        lat: sLoc?.pos_lat ? sLoc?.pos_lat : initialPos[1]
      })
      return sLoc
    })
  }

  const navigate = useNavigate();

  const listAll = () => {
    navigate("/dashboard/locations");
  }

  useEffect(() => {

    const locationItem = locationList[mapIndex]
    let sLocations = subLocationList.filter(sLoc => sLoc.location == locationItem?.pk)
    sLocations = sLocations.map((sLocation) => {
      const assets = assetList.filter(asset => Number(asset.location) === sLocation.location).filter(asset => Number(asset.sub_location) === sLocation.pk);
      let status = 0;
      if (assets && assets.length > 0) {
        getSystemStatus(assets[0].pk, (res) => {
          if (res.data && res.data.status === 'active')
            status = 1;
          else
            status = 0;
        })
      }

      return { ...sLocation, status, assets }
    })
    console.log('sLocations: ', sLocations);
    setSubLocations(sLocations)

    if (locationItem) {

      setTimeout(() => {
        if (locationItem.floor_type == 1) {
          const mapEl = document.querySelector('#indoor-map');
          if (!mapEl) return
          mapEl.innerHTML = ""
          const map = new Indoor.Map(mapEl, {
            floorplan: new Indoor.Floor({
              url: locationItem.floor_plan,
              width: 720,
              opacity: 0.9,
              zIndex: -100
            }),
            minZoom: 0.1,
            maxZoom: 100
          });

          sLocations.map(sLoc => {

            const marker = new Indoor.Marker([sLoc.pos_lng, sLoc.pos_lat], {
              draggable: false,
              zIndex: 100,
              size: 30,
              icon: { url: iconMarker },
              id: 0
            });

            // eslint-disable-next-line no-loop-func
            marker.on('ready', () => {
              marker.addTo(map);
            });
            return sLoc
          })

        }
        else
          mapRef.current.resize()

      }, 300);

    }

  }, [mapIndex, locationList, subLocationList])

  const locationData = locationTableData(locationList, setMapIndex, mapIndex);

  return (<DashboardLayout>
    <DashboardNavbar />
    <MDBox py={3}>
      <Grid container spacing={1}>
        <Grid item xs={8}>
          {locationList[mapIndex]?.floor_type != 1 ?
            <div style={{ height: '66vh', width: '100%', backgroundColor: 'white' }}>
              <Map
                ref={mapRef}
                initialViewState={{
                  longitude: 103.810715,
                  latitude: 1.377443,
                  zoom: 10.5
                }}
                mapStyle="mapbox://styles/mapbox/streets-v9"
                mapboxAccessToken="pk.eyJ1IjoiaW1hZ2lucmF5IiwiYSI6ImNrN2YwdXV3MDBoYTQzZ280dGYzdG40cmQifQ.EPVSF0iRfdmOey75gDrBLQ">
                {
                  subLocations.map(item => (item.pos_lng > -180 && item.pos_lng < 180) && (item.pos_lat > -90 && item.pos_lat < 90) && <Marker key={item.pk} longitude={item.pos_lng} latitude={item.pos_lat} anchor="bottom">
                    <img className="marker-item" height={30} src={iconMarker} alt="Green Marker" />
                  </Marker>)
                }

              </Map>
            </div>
            :
            <div style={{ height: '72vh', width: '100%', backgroundColor: 'white' }} id="indoor-map">
              &nbsp;
            </div>
          }
        </Grid>

        <Grid item xs={4}>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h6" color="white">
                Map List
              </MDTypography>
            </MDBox>
            <MDBox pt={3} className="map-list">
              <DataTable
                table={locationData}
                isSorted={false}
                entriesPerPage={false}
                showTotalEntries={false}
                noEndBorder
              />
            </MDBox>
          </Card>
        </Grid>

      </Grid>
    </MDBox>
    <MDBox py={3}>
      <Grid container spacing={1}>
        <Grid item xs={10}>
          &nbsp;
        </Grid>
        <Grid item xs={2}>
          <MDButton variant="gradient" color="info" fullWidth onClick={listAll}>
            List All
          </MDButton>
        </Grid>
      </Grid>
    </MDBox>
    <Footer />
  </DashboardLayout>)
}




export default Comp;
