import axios from "axios";
import * as actionTypes from "./actionTypes";
import { openSuccess, openError, openInfo } from "./notification"

export const getList = list => ({
  type: actionTypes.GET_ALARMS,
  list
})

export const getAlarms = () =>
  dispatch => {
    axios.get(`${process.env.REACT_APP_SERVER_URL}api/alarms/`, {
    })
      .then(res => {
        const alarmList = []
        res.data.results.map(item=> alarmList.push({...item, json_data: JSON.parse(item.json_data)}))
        dispatch(getList(alarmList));
      })
      .catch(() => {
      });
  }


export const createAlarm = (name, description, json_data) =>
  dispatch => {
    const token = localStorage.getItem("admin-token");
    const headers = { Authorization: `Token ${token}` };
    axios
      .post(`${process.env.REACT_APP_SERVER_URL}api/alarms/create`, { name, description, json_data: JSON.stringify(json_data) }, { headers })
      .then((res) => {
        if (res)
          dispatch(getAlarms());
        setTimeout(() => {
          dispatch(openSuccess(true, "Create Success!", "New Alarm has been created.", "Just now"));
        }, 300);
      })
      .catch(() => {
        setTimeout(() => {
          dispatch(openError(true, "Create Failed!", "There was an error to create.", "Just now"));
        }, 300);
      });
  };


export const updateAlarm = (id, name, description, json_data) =>
  dispatch => {
    const token = localStorage.getItem("admin-token");
    const headers = { Authorization: `Token ${token}` };
    axios
      .put(`${process.env.REACT_APP_SERVER_URL}api/alarms/update/${id}/`, {
        name, description, json_data: JSON.stringify(json_data)
      }, { headers })
      .then(res => {
        if (res)
          dispatch(getAlarms());
        setTimeout(() => {
          dispatch(openInfo(true, "Update Success!", "The Alarm has been updated.", "Just now"));
        }, 300);
      })
      .catch(() => {
        setTimeout(() => {
          dispatch(openError(true, "Update Failed!", "There was an error to update.", "Just now"));
        }, 300);
      });
  };

export const deleteAlarm = (id) =>
  dispatch => {
    const token = localStorage.getItem("admin-token");
    const headers = { Authorization: `Token ${token}` };
    axios
      .delete(`${process.env.REACT_APP_SERVER_URL}api/alarms/delete/${id}/`, { headers })
      .then(res => {
        if (res)
          dispatch(getAlarms());
        setTimeout(() => {
          dispatch(openSuccess(true, "Delete Success!", "The Alarm has been deleted.", "Just now"));
        }, 300);
      })
      .catch(() => {
        setTimeout(() => {
          dispatch(openError(true, "Delete Failed!", "There was an error to delete.", "Just now"));
        }, 300);
      });
  };
