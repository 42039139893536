
// @mui material components
import Icon from "@mui/material/Icon";
import { useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import AccountCircleIcon from '@mui/icons-material/AccountCircle';
// import GroupsIcon from '@mui/icons-material/Groups';
import PlaceIcon from '@mui/icons-material/Place';
import PropTypes from 'prop-types';

export default function data(list, deleteList, editList, locationList) {
  const [menu, setMenu] = useState({});

  const openMenu = (e, i) => {
    e.preventDefault()
    const updateMenu = { ...menu };
    updateMenu[`key-${i}`] = e.currentTarget;
    setMenu(updateMenu);
  }

  const closeMenu = (i) => {
    const updateMenu = { ...menu };
    updateMenu[`key-${i}`] = null;
    setMenu(updateMenu);
  }

  const deleteItem = (i) => {
    const item = list[i]
    deleteList(item.pk);
    closeMenu(i);
  }

  const editItem = (i) => {
    editList(i);
    closeMenu(i);
  }

  const renderMenu = (i) => (
    <Menu
      id="group-menu"
      anchorEl={menu[`key-${i}`]}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(menu[`key-${i}`])}
      onClose={() => closeMenu(i)}
    >
      <MenuItem onClick={() => editItem(i)}>Edit</MenuItem>
      <MenuItem onClick={() => closeMenu(i)}>Disable</MenuItem>
      <MenuItem onClick={() => deleteItem(i)}>Delete</MenuItem>
    </Menu>
  );

  const HeadItem = ({ name }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <PlaceIcon fontSize="small" />
      <MDTypography display="block" variant="button" fontWeight="medium" ml={1} lineHeight={1}>
        {name}
      </MDTypography>
    </MDBox>
  );

  HeadItem.propTypes = {
    name: PropTypes.string,
  };

  HeadItem.defaultProps = {
    name: ""
  }


  return {
    columns: [
      { Header: "Asset ID", accessor: "assetname", align: "left" },
      { Header: "Description", accessor: "description", align: "left" },
      { Header: "Location Name", accessor: "location", align: "left" },
      { Header: "Map Type", accessor: "map_type", align: "center" },
      { Header: "action", accessor: "action", align: "center" },
    ],

    rows: list.map((item, i) =>
    ({

      assetname: <HeadItem name={item.name} />,

      description: (
        <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
          {item.description}
        </MDTypography>
      ),

      location: (
        <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
          {locationList.filter(li => li.pk === item.location)[0]?.name}
        </MDTypography>
      ),

      map_type: (
        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          {locationList.filter(li => li.pk === item.location)[0]?.floor_type === 1 ? "Floor Plan" : "Global Map"}
        </MDTypography>
      ),

      action: (
        <MDTypography component="a" href="#" color="text">
          <Icon onClick={(e) => openMenu(e, i)}>more_vert</Icon>
          {renderMenu(i)}
        </MDTypography>
      )
    })
    )
  };
}
